<template>
    <div id="no-tg" v-if="!useWebApp().initData">
        ТОЛЬКО ЧЕРЕЗ ТГ {{ useWebApp().initData }}
    </div>
    <div id="main" v-else>

        <header class="header">
            <div class="container">
                <div class="profile-picture">
                    <img src="@/public/images/profile_pic.png" alt="profile picture">
                </div>
                <div class="account-data">
                    <div class="balance">{{ balance }} true coins</div>
                    <div class="account-button">
                        <button class="order-button"><img :src="`/user_profile_photo/${user_id}.png`" alt="account">
                        </button>
                    </div>
                </div>
            </div>
        </header>
    </div>


    <div class="products">
        <div class="container">
            <div class="heading">
                Мерч Маркет
            </div>
            <div class="products-items">
                <div class="product" v-for="product in products" :key="product.id">
                    <div class="product-image" onclick="changeProductImage(product)">
                        <img id="scarf" class="change-product-image"
                             :src="`/shop_img/${product.id}_1.png`" alt="шарф">
                    </div>
                    <div class="name">{{ product.name }}</div>
                    <div class="price">{{ product.price }} True Coins</div>
                    <div class="cart-button">
                        <select name="select" id="select" class="select"
                                v-model="product.currentSize"
                                v-if="product.sizes.length > 1">
                            <option :value="product_size.id" v-for="product_size in product.sizes"
                                    :key="product_size.id">{{ product_size.size }}
                            </option>

                        </select>
                        <button v-if="!(product.currentSize in cart)" @click="addToCart(product)"
                                class="add-to-cart-btn" data-name="Дутый шарф с карманом" data-price="999 True Coins"
                                data-image="images/шарф.png">В корзину
                        </button>
                        <div class="added" v-else>
                            <button class="less" @click="changeQuantity(product.currentSize, 'minus')">-</button>
                            <span class="quantity">{{ cart[product.currentSize].quantity }}</span>
                            <button class="more" @click="changeQuantity(product.currentSize, 'plus')">+</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cart">
        <div class="container">
            <div class="cart-heading">Ваш заказ:</div>

            <div class="cart-items">
                <div v-for="[key, cartItem] in cartEntries" class="cart-product">
                    <div class="cart-product-image">
                        <img :src="`/shop_img/${cartItem.product.id}_1.png`">
                    </div>
                    <div class="cart-product-info">
                        <div class="product-name">{{ cartItem.product_name }}
                        <span v-if="cartItem.size.size!== 'base'"> (Размер: {{ cartItem.size.size }})</span>
                        </div>
                        <div class="product-numbers">
                            <div class="product-quantity">
                                <button class="quantity-self-less" @click="changeQuantity(key, 'minus')">-</button>
                                <span class="cart-quantity">{{ cartItem.quantity }}</span>
                                <button class="quantity-self-more" @click="changeQuantity(key, 'plus')">+</button>
                            </div>
                            <div class="product-price">{{ cartItem.price * cartItem.quantity }} true coins</div>
                        </div>
                    </div>
                    <button class="product-rm-btn" @click="removeFromCart(key)" style="cursor: pointer;">✖️</button>
                </div>
            </div>


            <div class="total-price">Сумма: {{ this.totalPrice }} true coins</div>
            <div class="order-btn">
                <button @click="confirmOrder">Оформить заказ</button>
            </div>
        </div>
    </div>
</template>
<script>
import {Alert, ClosingConfirmation, Confirm, SettingsButton, useWebApp, useWebAppPopup, useWebAppTheme} from 'vue-tg'
import {defineComponent, onMounted, ref} from "vue";

export default defineComponent({
    data() {
        return {
            base_url: "https://truetechmerch.online/api",
            balance: 0,
            user_id: 0,
            cart: {},
            products: [],
        }
    },
    mounted() {
        console.log(useWebApp());
        fetch(this.base_url + '/me', {
            method: 'GET',
            headers: {
                'X-Token': this.token
            }
        }).then(response => response.json()).then(data => {
            this.balance = data.balance;
            this.user_id = data.user_id;
        })
        fetch(this.base_url + '/products', {
            method: 'GET',
            headers: {
                'X-Token': this.token
            }
        }).then(response => response.json()).then(data => {
            this.products = data.map(item => {
                return {
                    ...item,
                    currentSize: item.sizes[0].id,
                    currentSizeIndex: 0,
                };
            });
        })
    },
    computed: {
        totalPrice() {
            return Object.values(this.cart).reduce((total, item) => total + item.price * item.quantity, 0);
        },
        cartEntries() {
            return Object.entries(this.cart);
        }
    },
    methods: {
        confirmOrder() {
            if (this.totalPrice > this.balance) {
                useWebAppPopup().showAlert("Недостаточно средств");
                return;
            }
            if (Object.keys(this.cart).length === 0) {
                useWebAppPopup().showAlert("Выберите хотя бы один товар");
                return;
            }
            let data = Object.values(this.cart).map(item => {
                return {size_id: item.size.id, quantity: item.quantity}
            });
            fetch(this.base_url + '/confirm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Token': this.token
                },
                body: JSON.stringify(data)
            }).then(response => {
                if (response.status === 400) {
                    return response.json().then(errData => {
                        useWebAppPopup().showAlert(errData.detail); // выводим тело ответа при статусе 400
                    });
                } else if (response.status === 200) {
                    useWebAppPopup().showAlert("Покупка успешна! Перейди в бота");
                } else if (response.status === 500){
                    useWebAppPopup().showAlert("Произошла ошибка! Обратитесь в тех поддержку");; // выводим ошибку, если запрос не удался
                }
            })
                .catch(error => {
                    useWebAppPopup().showAlert("Произошла ошибка! Обратитесь в тех поддержку");; // выводим ошибку, если запрос не удался
                });
            this.cart = {};
        },
        changeQuantity(currentSize, action) {
            let cartItem;
            cartItem = this.cart[currentSize];
            if (action === 'plus') {
                if (cartItem.size.quantity_in_stock < cartItem.quantity + 1) {
                    useWebAppPopup().showAlert("Больше нельзя :(");
                    return;
                }
                cartItem.quantity++;
                if (this.totalPrice > this.balance) {
                    useWebAppPopup().showAlert("Недостаточно средств");
                    cartItem.quantity--;
                }
            } else {
                cartItem.quantity--;
            }
            if (cartItem.quantity < 1) {
                delete this.cart[currentSize];
            }
        },
        addToCart(product) {
            if (product.price + this.totalPrice > this.balance) {
                useWebAppPopup().showAlert("Недостаточно средств");
                return;
            }
            let size;
            size = product.sizes.find(size => size.id === product.currentSize);
            this.cart[product.currentSize] = {
                "quantity": 1,
                "price": product.price,
                "product_name": product.name,
                "product": product,
                "size": size,
            };
        },
        removeFromCart(currentSize) {
            delete this.cart[currentSize];
        }
    },
    setup() {
        const token = ref(null);
        onMounted(() => {
            const currentUrl = window.location.href;
            const url = new URL(currentUrl);
            token.value = url.searchParams.get('token');
        });
        return {
            useWebAppTheme,
            useWebApp,
            token
        };
    },
    components: {
        SettingsButton,
        ClosingConfirmation,
        Confirm,
        Alert
    }
});

</script>
<style scoped>
* {
    padding: 0;
    margin: 0;
}

img {
    display: block;
    width: 100%;
}


@font-face {
    font-family: 'Compact-Medium';
    src: url("@/public/fonts/MTSCompact-Medium.ttf");
}

@font-face {
    font-family: 'Compact-Regular';
    src: url("@/public/fonts/MTSCompact-Regular.ttf");
}

body {
    font-family: "Compact-Regular";
    font-size: 14px;
    background-color: #E0DFF1;
    color: #282828;
}


.container {
    margin: 0 auto;
    width: 100%;
    max-width: 900px;
    display: flex;
}

.header {
    width: 100%;
    background: white;
    box-shadow: 3px 3px 1px rgba(0, 0, 0, .05);
    padding: 7px 0;
    max-height: 57px;
}

.header .container {
    justify-content: space-between;
    align-items: center;
}

.product-quantity {
    width: 61px;
}

.profile-picture {
    padding: 0 10px;
}

.profile-picture img {
    height: 100%;
    width: 100%;
    max-width: 50px;
    max-height: 50px;
    border-radius: 50%;
}

.account-data {
    display: flex;
    align-items: center;
    padding: 0 5px;
}

.balance {
    margin: 0 12px;
    padding: 7px;
    border: solid 1px #ecbbbb;
    background-color: #fcecec;
    color: #e42b2b;
    border-radius: 5px;
    font-weight: bold;
}

.account-button img {
    height: 100%;
    max-height: 35px;
    max-width: 35px;
}

.account-button button {
    background-color: white;
    border-radius: 50%;
    border: none;
    cursor: pointer;
}

.products .container {
    background-color: #E0DFF1;
    display: flex;
    flex-direction: column;
}

.products .container .heading {
    font-family: 'Compact-Regular';
    text-align: center;
    margin: 5px 0;
    color: #e42b2b;
    font-size: 35px;
    font-weight: bold;
    padding: 0 10px;
}

.products-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.product {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 50px;
    padding: 0 7px;
}

.product-image img {
    width: 100%;
    flex: 1;
    height: 450px;
    object-fit: cover;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .product-image img {
        height: 200px;
    }
}

.name {
    font-family: "Compact-Medium";
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 5px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.price {
    font-family: "Compact-Regular";
    margin-bottom: 15px;
    color: #FF0032;
    font-weight: bold;
}

.cart-button {
    display: flex;
    align-self: center;
}

.add-to-cart-btn {
    border: none;
    background-color: #6451B8;
    color: white;
    padding: 10px 20px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
}

#t-shirt-cart-button {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
}

#t-shirt-cart-button button {
    padding: 10px 5px;
    flex-shrink: 0;
}

.cart-button select {
    align-self: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 3px 0;
}

.added {
    text-align: center;
    font-size: 20px;
    height: 35px;
}

.less, .more {
    border: none;
    color: white;
    background-color: #6451B8;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    cursor: pointer;
}

.less, .more, .quantity {
    display: inline;
    margin: 0 5px;
}

.quantity {
    font-family: "Compact-Regular";
    display: inline-block;
    width: 25px;
    text-align: center;
}

#t-shirt-added {
    display: none;
    text-align: center;
}

#t-shirt-added .quantity {
    width: 10px;
    text-align: center;
}

#t-shirt-added .less, #t-shirt-added .more {
    width: 15px;
    height: 15px;
    padding: 0;
    border-radius: 50%;
    cursor: pointer;
    text-align: center;
}


/* КОРЗИНА */
.cart {
    font-family: "Compact-Regular";
    width: 100%;
    background: white;
    padding: 10px 0;
}

.cart .container {
    width: 90%;
    display: block;
}

.cart-heading {
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.cart-product {
    display: flex;
    padding: 10px 0;
    justify-content: space-between;
    align-items: center;
}

.cart-product-image {
    display: inline-block;
    margin-right: 20px;
}

.cart-product-image img {
    width: 65px;
    height: 65px;
    object-fit: cover;
}

.cart-product-info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.product-numbers {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}

.product-quantity span {
    display: inline-block;
    width: 20px;
    text-align: center;
}

.product-quantity button {
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid black;
    padding: 1px 4px;
    cursor: pointer;
    text-align: center;
}

.product-quantity .quantity-self-less {
    padding: 1px 5px;
}

.cart-product .product-rm-btn {
    margin-left: 20px;
    border: none;
    background-color: transparent;
}

.total-price {
    text-align: right;
    font-size: 15px;
    font-weight: 600;
    padding-top: 25px;
    padding-bottom: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
}

.order-btn {
    text-align: center;
}

.order-btn button {
    border: none;
    background-color: #282828;
    color: white;
    padding: 15px 20px;
    margin: 10px 0;
    font-weight: bold;
    width: 100%;
    cursor: pointer;
}
</style>